.container-details {
  margin-left: 20%;
  margin-right: 20%;
  background-color:   #ececec;

}

#guias {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  

}

#guias td,
#guias th {
  border: 1px solid #ddd;
  padding: 8px;
  
}

#guias tr:nth-child(even) {
  background-color: #f2f2f2;
}

#guias tr:hover {
  background-color: #9cd6fe;
}

#guias th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #046ab0;
  color: white;
}

.imageRotateHorizontal {
  -moz-animation: spinHorizontal 0.9s infinite linear;
  -o-animation: spinHorizontal 0.9s infinite linear;
  -webkit-animation: spinHorizontal 0.9s infinite linear;
  animation: spinHorizontal 0.9s infinite linear;
}

@keyframes spinHorizontal {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@media (max-width: 768px) {
  .container-details {
    margin-left: 0;
    margin-right: 0;
  }
 
}